import React from "react";
import "./Button.scss";
const Button = (props) => {
  return (
    <button
      className={`button ${props.isPrimary && "primary-button"}`}
      onClick={props.Func}
      type={props.type}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
};

export default Button;
