export const privacyPolicyTabsData = [
  {
    id: 1,
    navTitle: "What information do we collect?",
    title: "information we collect",
    desc: (
      <div>
        <p>
          We collect information from you when you register on our site,
          subscribe to our newsletter or fill out a form.
        </p>
        <p>
          When ordering or registering on our site, as appropriate, you may be
          asked to enter your: name, e-mail address, mailing address or phone
          number. You may, however, visit our site anonymously.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "Information Usage for us",
    navTitle: "What do we use your information for?",
    desc: (
      <div>
        <p>
          Any of the information we collect from you may be used in one of the
          following ways:
        </p>
        <ul>
          <li>
            To personalize your experience (your information helps us to better
            respond to your individual needs)
          </li>
          <li>
            To improve our website (we continually strive to improve our website
            offerings based on the information and feedback we receive from you)
          </li>
          <li>
            To improve customer service (your information helps us to more
            effectively respond to your customer service requests and support
            needs)
          </li>
          <li>To send periodic emails</li>
          <li>
            The email address you provide may be used to send you information,
            respond to inquiries, and/or other requests or questions.
          </li>
        </ul>
        <p>
          <b>
            Note: If at any time you would like to unsubscribe from receiving
            future emails, we include detailed unsubscribe instructions at the
            bottom of each email.
          </b>
        </p>
      </div>
    ),
  },
  {
    id: 3,
    title: "information Protection",
    navTitle: "How do we protect your information?",
    desc: (
      <p>
        We implement a variety of security measures to maintain the safety of
        your personal information when you enter, submit, or access your
        personal information.
      </p>
    ),
  },
  {
    id: 4,
    title: "Cookies Usage",
    navTitle: "Do we use cookies?",
    desc: (
      <div>
        <p>
          Yes (Cookies are small files that a site or its service provider
          transfers to your computers hard drive through your Web browser (if
          you allow) that enables the sites or service providers systems to
          recognize your browser and capture and remember certain information
        </p>
        <p>
          We use cookies to compile aggregate data about site traffic and site
          interaction so that we can offer better site experiences and tools in
          the future. We may contract with third-party service providers to
          assist us in better understanding our site visitors. These service
          providers are not permitted to use the information collected on our
          behalf except to help us conduct and improve our business.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    navTitle: "Do we disclose any information to outside parties?",
    title: "Do we disclose any information to outside parties?",
    desc: (
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your
        personally identifiable information. This does not include trusted third
        parties who assist us in operating our website, conducting our business,
        or servicing you, so long as those parties agree to keep this
        information confidential. We may also release your information when we
        believe release is appropriate to comply with the law, enforce our site
        policies, or protect ours or others rights, property, or safety.
        However, non-personally identifiable visitor information may be provided
        to other parties for marketing, advertising, or other uses.
      </p>
    ),
  },
  {
    id: 6,
    title: "Your Consent:",
    navTitle: "Your Consent",
    desc: <p>By using our site, you consent to our privacy policy.</p>,
  },
  {
    id: 7,
    navTitle: "Changes to our Privacy Policy",
    title: "Changes to our Privacy Policy:",
    desc: (
      <p>
        Changes to our Privacy Policy: If we decide to change our privacy
        policy, we will post those changes on this page.
      </p>
    ),
  },
  {
    id: 8,
    title: "Contacting Us:",
    navTitle: "Contacting Us",
    desc: (
      <p>
        If there are any questions regarding this privacy policy you may contact
        us using the information below.
      </p>
    ),
  },
];
