import React from "react";
import "./Vision.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { visionData } from "../../Data/VisionData";
import VisionCard from "../../components/VisionCard/VisionCard";
const Vision = () => {
  return (
    <section className="vision">

        <div className="vision-header">
          <SectionTitle
            subTitle="Our Vision & Mission"
            title="We Are Here To Help You!"
            hasTitleUnderline={false}
          />
        </div>
        <div className="vision-cards-container">
          {visionData.map((item, index) => {
            return (
              <VisionCard
                key={index}
                title={item.title}
                img={item.img}
                desc={item.desc}
              />
            );
          })}
        </div>
    </section>
  );
};

export default Vision;
