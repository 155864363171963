import React from "react";
import "./PartnershipCard.scss";
const PartnershipCard = (props) => {
  return (
    <div className="partnership-card">
      <div className="partnership-card-img">
        <img src={props.img} alt={props.title} />
      </div>
      <div className="partnership-card-content">
        <h4>{props.title}</h4>
      </div>
    </div>
  );
};

export default PartnershipCard;
