import React from "react";
import "./VisionCard.scss";
const VisionCard = (props) => {
  return (
    <div className="vision-card">
      <div className="vision-card-img">
        <img src={props.img} alt={props.title} />
      </div>
      <div className="vision-card-content">
        <p>{props.title}</p>
        <h6>{props.desc}</h6>
      </div>
    </div>
  );
};

export default VisionCard;
