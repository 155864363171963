import React from "react";
import "./MarketCard.scss";
const MarketCard = (props) => {
  return (
    <div className="market-card">
      <div className="market-card-img">
        <img src={props.img} alt={props.title} />
      </div>
      <div className="market-card-content">
        <h5 className="market-card-subtitle">{props.subTitle}</h5>
        <h5 className="market-card-title">{props.title}</h5>
        <p className="market-card-desc">{props.desc}</p>
      </div>
    </div>
  );
};

export default MarketCard;
