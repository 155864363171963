import React from "react";
import "./ContactEmailToolTip.scss";
const ContactEmailToolTip = (props) => {
  return (
    <div className="contact-tooltip">
      <div className="contact-tooltip-wrapper">
        <div className="contact-tooltip-img">
          <img src={props.img} alt="ContactTip" />
        </div>
        <div className="contact-tooltip-email">
          <p>{props.email}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactEmailToolTip;
