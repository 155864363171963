import React, { useEffect } from "react";
import Hero from "../../Sections/Hero/Hero";
import Services from "../../Sections/Services/Services";
import Vision from "../../Sections/Vision/Vision";
import Partner from "../../Sections/Partner/Partner";
import Partnership from "../../Sections/Partnership/Partnership";
import Markets from "../../Sections/Markets/Markets";
import AboutUs from "../../Sections/AboutUs/AboutUs";
import History from "../../Sections/History/History";
import { useLocation } from "react-router-dom";
const Home = () => {
  const location = useLocation();
  useEffect(() => {
    const newPath = location?.state?.path?.slice(1);
    const section = document.querySelector(newPath);
    if (section) {
      const sectionTop = section?.offsetTop;
      window.scrollTo(0, sectionTop - location?.state?.margin);
    }
  }, [location?.state]);
  return (
    <section className="home">
      <Hero
        mainTitle="Vita La Vie Importing, Marketing and Distributing Pharmaceutical Ingredients, and Medical Devices."
        isMainPage={true}
        hasUnderline={false}
        hasButtons={false}
        HeroBackground={true}
      >
        <p>
          Vita La Vie (VLV) is a private company in Dubai specializing in
          importing, marketing, and distributing Active pharmaceutical
          ingredients, finished pharmaceutical products, and medical devices in
          many countries.
          <br />
          VLV works with many of the world’s leading companies to obtain a
          product portfolio through various agreements, partnerships, and joint
          ventures
        </p>
      </Hero>
      <div className="all-app-container">
        <Services />
        <Vision />
        <Partner />
        <History />
        <Partnership />
        <Markets />
        <AboutUs />
      </div>
    </section>
  );
};

export default Home;
