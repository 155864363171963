import React from 'react'
import './MapToolTip.scss'
import Location from  '../../assets/img/messages-2.svg'
const MapToolTip = () => {
  return (
    <div className='map-tooltip'>
        
        <img className='map-tooltip-img' src={Location} alt="Location" />
        
        <div className='map-tooltip-content'>
            <p>Unit No: 2852 ,DMCC Business Centre Level No 1 , Jewellery & Gemplex 3 Dubai ,United Arab Emirates</p>
        </div>
    </div>
  )
}

export default MapToolTip