import React from "react";
import "./HistoryCard.scss";
const HistoryCard = (props) =>
{
  return (

    <div className="history-card-wrapper">
      <div className="img-wrapper">

        <img className="histoy-card-img" src={props.historyItem.img} alt={props.historyItem.title} />

      </div>
      <div className="history-card-timelines-wrapper">
        <div className="history-card-timeline">
          <div>
            <span className="history-card-timeline-rounder"></span>
            <span className="history-card-timeline-line"></span>
          </div>
          <div className="history-card-timeline-content">
            <h5>{props.historyItem.subTitle}</h5>
            <h5>{props.historyItem.title}</h5>
            <p>{props.historyItem.desc}</p>
          </div>
        </div>
        {props.nextHistorySlide && (
          <div className="history-card-timeline" onClick={() =>
          {
            props.handleSelectedHistoryCard(props.nextHistorySlide);
          }}>
            <div>
              <span className="history-card-timeline-rounder"></span>
              <span className="history-card-timeline-line"></span>
            </div>
            <div className="history-card-timeline-content">
              <h5>{props.nextHistorySlide?.subTitle}</h5>
              <h5>{props.nextHistorySlide?.title}</h5>
              <p>{props.nextHistorySlide?.desc}</p>
            </div>

          </div>
        )}
      </div>
    </div>

  );
};

export default HistoryCard;
