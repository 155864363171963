import Img1 from "../assets/img/partnership/medicin.svg";
import Img2 from "../assets/img/partnership/unlimited.svg";

export const partnershipData = [
  {
    id: 1,
    img: Img2,
    title: "Raw Materials",
  },
  {
    id: 2,
    img: Img1,
    title: "FDF’s (Final Dosage Forms)",
  },
];
