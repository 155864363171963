import React from "react";
import "./SectionTitle.scss";
const SectionTitle = (props) => {
  return (
    <div className="section-title">
      {props.subTitle && (
        <h5 className="section-title-subTitle">{props.subTitle}</h5>
      )}
      {props.title && (
        <h3
          className={`${
            props.hasTitleUnderline
              ? "section-title-main-underline"
              : "section-title-main"
          }`}
        >
          {props.title}
        </h3>
      )}
      {props.desc && <p className="section-title-desc">{props.desc}</p>}
    </div>
  );
};

export default SectionTitle;
