import React from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useLoadScript } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "65vh",
  borderRadius: "32px",
};
const center = {
  lat: 25.1895,
  lng: 55.29357,
};
// const markerPosition = {
//   lat: 25.0584304,
//   lng: 55.1332192,
// };
const Map = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyARIUr-wH4wAJ1yZdWzFRRRDy0z4pT0L24",
  });

  if (loadError) return "Error loading Maps";
  if (!isLoaded) return "Loading Maps";

  return isLoaded ? (
    <GoogleMap mapContainerStyle={mapContainerStyle} zoom={15} center={center}>
      <MarkerF position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(Map);
