import servicesImg1 from "../assets/img/services/1.webp";
import servicesImg2 from "../assets/img/services/2.webp";
import servicesImg3 from "../assets/img/services/3.webp";
import servicesImg4 from "../assets/img/services/4.webp";
import servicesImg5 from "../assets/img/services/5.webp";
export const servicesData = [
  {
    id: 1,
    img: servicesImg1,
    title: "Supply Chain",
    desc: "Together with our channel partners, we ensure that we achieve a high distribution index that allows us to widely promote our products.",
  },
  {
    id: 2,
    img: servicesImg2,
    title: "Market Research",
    desc: "Selecting products for company portfolio after identifying the market potential and customers’ needs.",
    desc2:
      "Studying market conditions and making recommendations based on the outcomes.",
  },
  {
    id: 3,
    img: servicesImg3,
    title: "Logistics services",
    desc: (
      <>
        <p>We carry out all stages of importing products:</p>
        <ul className="p-0 m-0">
          <li>Importing licenses</li>
          <li>Products clearance</li>
          <li>Products pricing</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    img: servicesImg4,
    title: "Marketing Affairs",
    desc: "Our marketing department is responsible for developing and implementing the strategic marketing plan for each product in operation with sales department.",
    desc2:
      "We apply our own techniques in addition to globally recognized branding techniques to adapt to MENA market and provide the best solution for each product",
  },

  {
    id: 5,
    img: servicesImg5,
    title: "Registration",
    desc: "We carry out the entire registration process from the very beginning.",
    desc2:
      "Our thorough preparation, well-trained team, and strong relationships with key persons in the Ministries of Healthcare, facilitate and accelerate the registration process",
    desc3:
      "Moreover, we are responsible for renewing companies and products registration.",
  },
];
