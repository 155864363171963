import React, { useState, useRef, useEffect } from "react";
import "./PrivacyPolicy.scss";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Hero from "../Hero/Hero";
import { useMediaQuery } from "react-responsive";
import { privacyPolicyTabsData } from "../../Data/PrivacyPolicyData";

const PrivacyPolicy = () => {
  const [selectedItem, setSelectedItem] = useState(privacyPolicyTabsData[0]);
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" });
  const scrollableContentRef = useRef(null);
  const tabContainerRef = useRef(null);

  useEffect(() => {
    scrollToSection(selectedItem.id);
  }, [selectedItem]);

  const handleSelectedPrivacyItem = (item) => {
    setSelectedItem(item);
    scrollToSection(item.id);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (scrollableContentRef.current && section) {
      // Calculate the scroll position to make the selected item appear at the top of the scrollable content
      const scrollY =
        section.offsetTop - scrollableContentRef.current.offsetTop;
      // Scroll to the calculated position
      scrollableContentRef.current.scrollTo({
        top: scrollY,
        behavior: "smooth",
      });
    }
  };
  const HeroContent = () => {
    return (
      <>
        <p>
          Online Privacy Policy Only:
          <br />
          This online privacy policy applies only to information collected
          through our website and not to information collected offline.
        </p>
      </>
    );
  };
  return (
    <div className="privacy-policy" id="privacy-policy">
      <Hero
        mainTitle="Privacy Policy"
        content={<HeroContent />}
        hasUnderline={true}
        hasButtons={false}
        HeroBackground={false}
        hasImg={false}
        isMainPage={false}
      >
        <p>
          Online Privacy Policy Only:
          <br />
          This online privacy policy applies only to information collected
          through our website and not to information collected offline.
        </p>
      </Hero>
      <div className="all-app-container">
        


      
      {isMobile &&
        privacyPolicyTabsData.map((dataItem) => {
          return (
            <div className="mobile-privacyPolicy">
              <h4 className="privacyPolicy-nav">{dataItem.navTitle}</h4>
              <span className="privacyPolicy-title">{dataItem.title}</span>
              <div className="privacyPolicy-desc">{dataItem.desc}</div>
            </div>
          );
        })}
      
      {!isMobile && (

          <Tab.Container
            className={"tabs-container"}
            id="left-tabs-example"
            defaultActiveKey="1"
            ref={tabContainerRef}
          >
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column gap-4">
                  {privacyPolicyTabsData.map((item) => {
                    return (
                      <Nav.Link
                        eventKey={item.id}
                        onClick={() => handleSelectedPrivacyItem(item)}
                        active={selectedItem === item}
                      >
                        {item.navTitle}
                      </Nav.Link>
                    );
                  })}
                </Nav>
              </Col>
              <Col sm={9}>
                <div className="scrollable-content" ref={scrollableContentRef}>
                  {privacyPolicyTabsData.map((dataItem) => {
                    return (
                      <div
                        className="PrivacyPolicyData-content"
                        id={dataItem.id}
                      >
                        <span className="privacyPolicyTabsDataItem-title">
                          {dataItem.title}
                        </span>
                        <div className="privacyPolicyTabsDataItem-desc">
                          {dataItem.desc}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Tab.Container>
      )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
