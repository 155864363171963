import React from "react";
import "./ContactUs.scss";
import { Container } from "react-bootstrap";
import Button from "../../components/Button/Button";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ContactEmailToolTip from "../../components/ContactEmailToolTip/ContactEmailToolTip";
import MessagesImg from "../../assets/img/messages.svg";
import MapToolTip from "../../components/MapToolTip/MapToolTip";
import Map from "../Map/Map";
const ContactUs = () => {
  return (
    <section className="contact-us" id="contact">
     
        <div className="contact-us-wrapper">
          <div className="contact-us-form">
            <SectionTitle title="Contact Us !" />
            <ContactEmailToolTip img={MessagesImg} email="info@vitalavie.com" />
            <MapToolTip />

            <form action="send_mail.php" method="POST">
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                required
              ></input>
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
              ></input>
              <textarea
                name="message"
                placeholder="message"
                required
              ></textarea>
              <Button isPrimary={true} type="submit" text="Send Message" />
            </form>
          </div>
          <div className="map-wrapper">
            <Map disable={true} />
          </div>
        </div>

    </section>
  );
};

export default ContactUs;
