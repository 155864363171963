import countryImg1 from "../assets/img/History/1.webp";
import countryImg2 from "../assets/img/History/2.webp";
import countryImg3 from "../assets/img/History/3.webp";
import countryImg4 from "../assets/img/History/4.webp";

export const historyData = [
  {
    id: 0,
    img: countryImg1,
    subTitle: "2018",
    title: "The Founding Year",
    desc: "Vita La Vie was established in Dubai in 2018",
  },
  {
    id: 1,
    img: countryImg2,
    subTitle: "2019 - 2020",
    title: "The Beginning Of expand.",
    desc: "Vita La Vie Started and expanded working in the Iraq market",
  },
  {
    id: 2,
    img: countryImg3,
    subTitle: "2021",
    title: "New Market",
    desc: "Vita La Vie started working in the Sudan market",
  },
  {
    id: 3,
    img: countryImg4,
    subTitle: "2022 - 2023",
    title: "Potential Market",
    desc: "Work on potential markets in Oman and Saudi Arabia",
  },
];
