import React from "react";
import "./Services.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { servicesData } from "../../Data/ServicesData";
import ServicesCard from "../../components/ServicesCard/ServicesCard";
const Services = () => {
  return (
    <section className="services" id="services">
        <div className="services-header">
          <SectionTitle
            subTitle="Our Services"
            title="Types of services"
            desc="We provide many services that may interest you, including:"
            hasTitleUnderline={false}
          />
        </div>
        <div className="services-content-container-one">
          {servicesData.slice(0, 3).map((service, index) => {
            return (
              <ServicesCard
                key={index}
                img={service?.img}
                title={service?.title}
                desc={service?.desc}
                desc2={service?.desc2}
                desc3={service?.desc3}
              />
            );
          })}
        </div>
        <div className="services-content-container-two">
          {servicesData.slice(3, 5).map((service, index) => {
            return (
              <ServicesCard
                key={index}
                img={service?.img}
                title={service?.title}
                desc={service?.desc}
                desc2={service?.desc2}
                desc3={service?.desc3}
              />
            );
          })}
        </div>
    </section>
  );
};

export default Services;
