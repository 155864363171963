import React from "react";
import "./Hero.scss";
import Button from "../../components/Button/Button";
import MobileDoctorImg from "../../assets/img/HeroDoctorMobileMain.webp";
import { useMediaQuery } from "react-responsive";

const Hero = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" });
  return (
    <section
      id="home"
      className={`hero ${
        props.HeroBackground && !isMobile
          ? "HeroBackgroundWithImage"
          : "HeroBackgroundWithoutImage"
      } ${props.isErrorPage && "error-page"}`}
    >
      <div className="hero-content-article">
        <h3>{props.mainTitle}</h3>
        <p className={props.hasUnderline && `hasUnderline`}>
          {props.isMainPage ? (
            <p>
              Vita La Vie (VLV) is a private company in Dubai specializing in
              importing, marketing, and distributing Active pharmaceutical
              ingredients, finished pharmaceutical products, and medical devices
              in many countries.
              <br />
              VLV works with many of the world’s leading companies to obtain a
              product portfolio through various agreements, partnerships, and
              joint ventures
            </p>
          ) : (
            <>{props.content}</>
          )}
        </p>
        {props.hasButtons && (
          <div className="hero-content-article-btns">
            <Button isPrimary={true} text={"About Us"} />
            <Button isPrimary={false} text={"Contact Us"} />
          </div>
        )}
      </div>
      {isMobile && props.hasImg && (
        <img className="hero-img" src={MobileDoctorImg} alt="MobileDoctorImg" />
      )}
    </section>
  );
};

export default Hero;
