import DART from "../assets/img/vision/1.svg";
import EYE from "../assets/img/vision/2.svg";
export const visionData = [
  {
    id: 1,
    img: EYE,
    title: "Our Vision",
    desc: "Is to improve the lives of patients by providing innovative treatments that address unmet medical needs",
  },
  {
    id: 2,
    img: DART,
    title: "Our Mission",
    desc: "To become leading company by providing healthy and quality life for patients",
  },
];
