import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import ContactUs from "../Sections/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";

const Layout = () => {
  return (
    <div className="App-layout">
      <Header />
      <Outlet />
      <div className="all-app-container">
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
