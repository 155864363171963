import React from "react";
import "./ServicesCard.scss";
const ServicesCard = (props) => {
  return (
    <div className="services-card">
        <h4>{props.title}</h4>
      <div className="card-img">
        <img src={props.img} alt={props.title} />
      </div>
      <div className="card-content">
        <p>{props.desc}</p>
        <p>{props?.desc2}</p>
        <p>{props?.desc3}</p>
      </div>
    </div>
  );
};

export default ServicesCard;
