import React from "react";
import "./AboutUs.scss";
import AboutImg from "../../assets/img/About.svg";
const AboutUs = () => {
  return (
    <section className="about-us" id="about">
      <div className="about-us-wrapper">
        <div className="about-us-content">
          <p>
            Vita La Vie is a private company in Dubai specializing in importing,
            marketing, and distributing Active pharmaceutical ingredients,
            finished pharmaceutical products, and medical devices in many
            countries.
          </p>
          <p>
            VLV works with many of the world’s leading companies to obtain a
            product portfolio through various agreements, partnerships, and
            joint ventures
          </p>
        </div>
        <div className="about-us-img">
          <img src={AboutImg} alt="AboutImg" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
