import React from "react";
import "./Error.scss";
import Hero from "../Hero/Hero";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import ERRORIMG from "../../assets/img/404.webp";
const Error = () => {
  const navigate = useNavigate();
  const HeroContent = () => {
    return (
      <div className="error-page-content">
        <img src={ERRORIMG} alt="ERRORIMG" />
        <h1>Oops!</h1>
        <p>Sorry, we’re not able to find what you were looking for</p>
        <Button isPrimary text="Back to Home Page" Func={() => navigate("/")} />
      </div>
    );
  };
  return (
    <section className="error-page">
      <Header />
      <Hero
        content={<HeroContent />}
        isMainPage={false}
        isErrorPage={true}
        hasUnderline={false}
        hasButtons={false}
        HeroBackground={false}
      />
    </section>
  );
};

export default Error;
