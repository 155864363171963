import countryImg1 from "../assets/img/markets/sudan.webp";
import countryImg2 from "../assets/img/markets/iraq.webp";
import countryImg3 from "../assets/img/markets/sa.webp";
import countryImg4 from "../assets/img/markets/algeria.webp";
import countryImg5 from "../assets/img/markets/oman.webp";
import countryImg6 from "../assets/img/markets/morocco.webp";
export const marketsData = [
  {
    id: 1,
    img: countryImg1,
    subTitle: "Sudan",
    title: "Vita la vie market",
    desc: "Burj Al Fateh",
  },
  {
    id: 2,
    img: countryImg2,
    subTitle: "Iraq",
    title: "Vita la vie market",
    desc: "Al Malwiya Tower",
  },
  {
    id: 3,
    img: countryImg3,
    subTitle: "Saudi Arabia",
    title: "Pipeline 2024",
    desc: "Kingdom Tower",
  },

  {
    id: 4,
    img: countryImg4,
    subTitle: "Algeria",
    title: "Pipeline 2024",
    desc: "Tha Maqam Echahid",
  },
  {
    id: 5,
    img: countryImg5,
    subTitle: "Oman",
    title: "Potential Market",
    desc: "Sultan Qaboos Grand Mosque",
  },
  {
    id: 6,
    img: countryImg6,
    subTitle: "Morroco",
    title: "Potential Market",
    desc: "Hasan II Mosque, Casablanca",
  },
];
