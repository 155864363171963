import React from "react";
import "./Partner.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
const Partner = () => {
  return (
    <section className="partner" id="partner">
        <div className="partner-header">
          <SectionTitle
            subTitle="Our Partners"
            title="Our Partners Over The World"
            hasTitleUnderline={true}
          />
        </div>
    </section>
  );
};

export default Partner;
