import React, { useState, useEffect } from "react";
import "./Header.scss";
import LOGO from "../../assets/img/LOGO.svg";
// import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MobileMenu from "../../assets/img/MobileMenu.png";
import XImg from "../../assets/img/x.png";
const Header = () => {
  const [activeLink, setActiveLink] = useState("#home");
  const [isFixed, setIsFixed] = useState(false);
  const navLinks = [
    {
      path: "/#home",
      display: "Home",
      margin: 10,
    },
    {
      path: "/#services",
      display: "Services",
      margin: 10,
    },
    {
      path: "/#markets",
      display: "Markets",
      margin: 120,
    },
    {
      path: "/#about",
      display: "About",
      margin: 100,
    },
    {
      path: "/#contact",
      display: "Contact",
      margin: 10,
    },
    {
      path: "/privacy-policy",
      display: "Privacy Policy",
      margin: 10,
    },
  ];
  const OpenMenuNav = () => {
    document.getElementById("menu-nav-overlay").style.width = "90%";
  };
  const CloseMenuNav = () => {
    document.getElementById("menu-nav-overlay").style.width = "0%";
  };
  const navigate = useNavigate();

  const handleLinkClick = (path, margin) => {
    const newPath = path.slice(1);
    setActiveLink(path);
    navigate(path, {
      state: {
        path: path,
        margin: margin,
      },
    });
    const section = document.querySelector(newPath);
    if (section) {
      const sectionTop = section.offsetTop;
      window.scrollTo(0, sectionTop - margin);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      let active = "#home";
      navLinks.forEach((link) => {
        const newLink = link.path.slice(1);
        const section = document.querySelector(`${newLink}`);
        if (section) {
          const sectionTop = section.offsetTop;
          const sectionBottom = sectionTop + section.clientHeight;
          const scrollY = window.scrollY;
          if (scrollY + 120 >= sectionTop && scrollY + 120 < sectionBottom) {
            active = newLink;
            setActiveLink(active);
          }
        }
        CloseMenuNav();
      });

      if (window.scrollY > 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isFixed ? "fixed-header" : ""}`}>
      <div className="header-logo-container" onClick={() => navigate("/")}>
        <img src={LOGO} alt="LOGO" />
      </div>
      <div className="header-menu-list">
        {navLinks.slice(0, 5).map((item, index) => {
          return (
            <>
              {item.path === "/" + activeLink ? (
                <a
                  className={"header-menu-item-link active-link"}
                  onClick={() => handleLinkClick(item.path, item.margin)}
                >
                  {item.display}
                </a>
              ) : (
                <a
                  className={"header-menu-item-link"}
                  onClick={() => handleLinkClick(item.path, item.margin)}
                >
                  {item.display}
                </a>
              )}
            </>
          );
        })}
      </div>
      {/*  // !===================Responsive================== */}
      <img
        className="mobile-menu"
        src={MobileMenu}
        alt="MobileMenu"
        onClick={() => OpenMenuNav()}
      />
      <div className="mobile-menu-overlay" id="menu-nav-overlay">
        <img
          src={XImg}
          alt="XImg"
          className="closebtn"
          onClick={() => CloseMenuNav()}
        />
        <div className="mobile-menu-overlay-content">
          {navLinks.map((item, index) => {
            return (
              <>
                {item.path === "/" + activeLink ? (
                  <a
                    className={"header-menu-item-link active-link"}
                    onClick={() => handleLinkClick(item.path, item.margin)}
                  >
                    {item.display}
                  </a>
                ) : (
                  <a
                    className={"header-menu-item-link"}
                    onClick={() => handleLinkClick(item.path, item.margin)}
                  >
                    {item.display}
                  </a>
                )}
              </>
            );
          })}
        </div>
      </div>
    </header>
  );
};

export default Header;
