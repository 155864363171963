import React from "react";
import "./Markets.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { marketsData } from "../../Data/MarketsData";
import MarketCard from "../../components/MarketCard/MarketCard";
const Markets = () => {
  return (
    <section className="markets" id="markets">
      <div className="markets-header">
        <SectionTitle
          subTitle="Our Markets"
          title="Our Markets Around The World"
          hasTitleUnderline={true}
        />
      </div>
      <div className="markets-cards-container">
        {marketsData.map((market, index) => {
          return (
            <MarketCard
              key={index}
              subTitle={market.subTitle}
              title={market.title}
              img={market.img}
              desc={market.desc}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Markets;
