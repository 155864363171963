import React, { useState } from "react";
import "./History.scss";
// import { Container } from "react-bootstrap";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Slider from "react-slick";
import HistoryCard from "../../components/HistoryCard/HistoryCard";
import { historyData } from "../../Data/HistoryData";
import ArrowDownActive from "../../assets/img/History/arrow-down-active.svg";
import ArrowDownInactive from "../../assets/img/History/arrow-down-inactive.svg";
import ArrowUpActive from "../../assets/img/History/arrow-up-active.svg";
import ArrowUpInactive from "../../assets/img/History/arrow-up-inactive.svg";
const History = () => {
  const [currentSlideIndex, setcurrentSlideIndex] = useState(0);
  const [slider, setSlider] = useState(null);

  const handleSelectedHistoryCard = (selectedCard) => {
    const selectedIndex = historyData.findIndex(
      (item) => item.id === selectedCard.id
    );

    if (selectedIndex !== -1) {
      slider.slickGoTo(selectedIndex);
    }
  };
  function NextArrow(props) {
    const { slideCount, currentSlide, className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        {currentSlide + 1 !== slideCount ? (
          <img src={ArrowDownActive} alt="ArrowUpActive" />
        ) : (
          <img src={ArrowDownInactive} alt="ArrowUpActive" />
        )}
      </div>
    );
  }

  function PrevArrow(props) {
    const { slideCount, currentSlide, className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        {currentSlide !== 0 || currentSlide + 1 === slideCount ? (
          <img src={ArrowUpActive} alt="ArrowUpActive" />
        ) : (
          <img src={ArrowUpInactive} alt="ArrowUpActive" />
        )}
      </div>
    );
  }
  const settings = {
    dots: false,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    verticalSwiping: true,
    navigator: false,
    beforeChange: function (currentSlide, nextSlide) {
      setcurrentSlideIndex(nextSlide);
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <section className="history">
      <SectionTitle
        subTitle="Our History"
        title="Vita la Vie History Time Line"
        hasTitleUnderline={true}
      />
      <Slider {...settings} ref={(c) => setSlider(c)}>
        {historyData?.map((historyItem, index) => {
          return (
            <HistoryCard
              key={index}
              historyItem={historyData[currentSlideIndex]}
              nextHistorySlide={historyData[currentSlideIndex + 1]}
              handleSelectedHistoryCard={handleSelectedHistoryCard}
            />
          );
        })}
      </Slider>
    </section>
  );
};

export default History;
