import React from "react";
import "./Partnership.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { partnershipData } from "../../Data/PartnershipData";
import PartnershipCard from "../../components/PartnershipCard/PartnershipCard";
const Partnership = () => {
  return (
    <section className="partnership">
      <div className="partnership-header">
        <SectionTitle
          title="Key Area Of Partnership"
          hasTitleUnderline={true}
        />
      </div>
      <div className="partnership-cards-container">
        <h5>
          We are practically interested in products in the following fields:
        </h5>
        <div className="partnership-cards-wrapper">
          {partnershipData.map((partnership, index) => {
            return (
              <PartnershipCard
                key={index}
                title={partnership.title}
                img={partnership.img}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Partnership;
