import "./App.css";
// import ContactUs from "./Sections/ContactUs/ContactUs";
// import Header from "./components/Header/Header";
// import Footer from "./components/Footer/Footer";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./Sections/PrivacyPolicy/PrivacyPolicy";
import Error from "./Sections/Error/Error";
import Layout from "./Layout/Layout";
import Home from "./Sections/Home/Home";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route index path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
