import React from "react";
import "./Footer.scss";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// @.hero

const Footer = () => {
  const CurrentYear = new Date().getFullYear();
  return (
    <footer className="footer">

        <div className="footer-wrapper">
          <div className="footer-links">
            <ul>
              <li>
                <NavLink
                  to="/privacy-policy"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Privacy Policy
                </NavLink>
              </li>
            </ul>
          </div>
          <hr />
          <div className="footer-socials-copyright">
            <div className="copyright">
              <p>
                {CurrentYear} <a href="">© Vita la vie </a> All rights reserved
              </p>
            </div>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
